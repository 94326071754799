import React from "react";
import Button from "@reusecore/src/elements/Button";
import CryptoJS from "crypto-js";

function getRequestButton(props) {
  const fetchRecord = (rec) => {
    let data;
    let link = "";
    let prodLink = "https://us-central1-taxigy-bfe19.cloudfunctions.net/api";
    let devLink = "http://localhost:8080";

    if (rec.from == "zipCode") {
      var apiKey="5b48848c7fa63eb0b5fbfd72cafaa247c1e58d5c149908a1357d7833a9f1be68f19f6ae01b254a4f09ca55116600df2297a42e95f36bc9c5fbd900c961e2c8a2"
      link = prodLink +'/v10/tax?apiKey='+ apiKey + '&zipCode=' + rec.data;
    } else {
      var apiKey="5b48848c7fa63eb0b5fbfd72cafaa247c1e58d5c149908a1357d7833a9f1be68f19f6ae01b254a4f09ca55116600df2297a42e95f36bc9c5fbd900c961e2c8a2"
      link = prodLink + '/v10/tax/county/state?apiKey='+ apiKey + '&location=' + rec.data;
    }
   
    return fetch(link)
      .then(async (response) => {
        const res = await response.json();
        return res;
    })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const newVal = async () => {
    let t = await fetchRecord(props);
    return t;
  };

  return (
    <Button
      onClick={async () => props.openModalFunc(await newVal())}
      style={{ marginLeft: "10px" }}
      {...props.buttonStyle}
      title="Find Tax"
    />
  );
}

const genrateApi = (datae) => {
  var encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(datae),
    CryptoJS.enc.Hex.parse("14d3135d02f91437169464275a38ded7d3cb2d794bc084a0001ff219502943ab"),
    {
      iv: CryptoJS.enc.Hex.parse("0779d50565834a2808f436fa121ebc3b"),
    }
  );
  return encrypted.ciphertext.toString();
};
export default getRequestButton;
