import Client1 from '../../assets/image/saas/testimonial/client-1.jpg';
import Client2 from '../../assets/image/agency/client/denny.png';

export const Faq = [
  {
    id: 1,
    expend: true,
    title: 'Developer Documentation',
    description:
      'For developer documentation or information about the taxigy API, please visit the Documentation section. ',
  },
  {
    id: 2,
    title: 'Pricing Information',
    description:
      'For pricing information and details about our pricing options, please visit the Pricing section. ',
  },
  {
    id: 3,
    title: 'For Technical Issues',
    description:
      'For technical issues or general inquiries, please send us an email at the address above. To ensure a timely response, please include any details we may need to assist you.',
  },
  {
    id: 4,
    title: 'For Any Query!',
    description:
      'If you would like to contact us directly, please send us a message at support@taxigy.com. .',
  },
  // {
  //   id: 4,
  //   title: 'New update fixed all bug and issues?',
  //   description:
  //     "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
  // },
];

export const Features = [
  {
    id: 1,
    icon: 'flaticon-stopwatch',
    title: 'taxigy.com',
    description:
      'taxigy.com offers an online look-up service and a developer API for sales tax data. By providing as little as a zip code, we can determine the sales tax rates for your customers in their specific geographical area. In most states, the calculation of sales tax and the information changes many times throughout the year.',
      // Our service provides you with a method to get this information quickly and easily and with no maintenance. The taxigy team is dedicated to providing you with the critical up-to-date information you need so you can focus on the important parts of your business. Get started today!
  },
  {
    id: 2,
    icon: 'flaticon-briefing',
    title: 'How Does It Work?',
    description:
      'taxigy.com works by submitting geographic information such as city, state, and zip code from your application or website. This data is used determine the correct sales tax information matching the provided location. Our service then returns that information to your application or website. ',
      // Your application uses the returned data to apply the correct sales tax rates to your customer transaction in real-time. Click below to learn more about our service and pricing options. We can help you determine which option is right for your business application or website. Get started today! .
  },
  {
    id: 3,
    icon: 'flaticon-flask',
    title: 'Should I Use An API?',
    description:
      'An API (Application Programming Interface) is a way for developers to avoid re-inventing the wheel by using connected services such as taxigy.com to retrieve complicated information quickly and easily within an application or website. ',
      // By using third-party services, you are removing significant workload from your team allowing them to focus on your core business. Our service is built on highly scalable and reliable platforms to ensure maximum uptime for your website or application. We also provide detailed documentation to simplify the integration process. Get started today!
  },
  // {
  //   id: 4,
  //   icon: 'coding-language',
  //   title: 'Mobile Application',
  //   description:
  //     'Use our mobile application on the go to retrieve sales tax rates for any location. ',
  // },
  // {
  //   id: 5,
  //   icon: 'flaticon-briefing',
  //   title: 'Online Documentation',
  //   description:
  //     'We provide detailed documentation to help you connect your service to our API. Get started today! ',
  // },
  // {
  //   id: 6,
  //   icon: 'flaticon-flask',
  //   title: 'Account Portal Access',
  //   description:
  //     'Access our portal to view account details, lookup rates, and manage your subscription.',
  // },

];




export const Footer_Data = [
  {
    title: 'SITE LINK',
    menuItems: [
      {
        url: '#',
        text: 'Pricning',
      },
      {
        url: '#',
        text: 'Sales-Tax-Lookup',
      },
      {
        url: '#',
        text: 'WooCommerce-Sales-Tax-Plugin',
      },
      {
        url: '#',
        text: 'Documentation',
      },
      // {
      //   url: '#',
      //   text: 'Contact',
      // },
      // {
      //   url: '#',
      //   text: 'Account Portal Login',
      // },
    ],
  },
  // {
  //   title: 'LEGAL',
  //   menuItems: [
  //     {
  //       url: '#',
  //       text: 'Customer Agreement',
  //     },
  //     {
  //       url: '#',
  //       text: 'Privacy Policy',
  //     },
  //     {
  //       url: '#',
  //       text: 'Service Level Agreement',
  //     },
  //     // {
  //     //   url: '#',
  //     //   text: 'Site Map',
  //     // },
  //     // {
  //     //   url: '#',
  //     //   text: 'Store Hours',
  //     // },
  //   ],
  // },
  // {
  //   title: 'LEGAL',
  //   menuItems: [
  //     {
  //       url: '#',
  //       text: 'Customer Agreement',
  //     },
  //     {
  //       url: '#',
  //       text: 'Privacy Policy',
  //     },
  //     {
  //       url: '#',
  //       text: 'Service Level Agreement',
  //     },
  //     {
  //       url: '#',
  //       text: 'Permissions',
  //     },
  //     {
  //       url: '#',
  //       text: 'Speaker requests',
  //     },
  //   ],
  // },
  {
    title: 'CONTACT US',
    menuItems: [
      {
        url: '#',
        text: 'info@taxigy.com',
      },
      {
        url: '#',
        text: 'Contact',
      },
      {
        url: '#',
        text: 'Account Portal Login',
      },
      // {
      //   url: '#',
      //   text: 'Software principles',
      // },
      // {
      //   url: '#',
      //   text: 'Unwanted software policy',
      // },
      // {
      //   url: '#',
      //   text: 'Responsible supply chain',
      // },
    ],
  },
];

export const Service = [
  {
    id: 1,
    icon: 'flaticon-briefing',
    title: 'Programmatic API',
    description:
      'Connect our API to your website or application for on-demand sales tax data .',
  },
  {
    id: 2,
    icon: 'flaticon-trophy',
    title: 'Online Lookup',
    description:
      'Our online lookup service provides full sales tax data lookups via account portal.',
  },
  {
    id: 3,
    icon: 'flaticon-atom',
    title: '24/7 Support',
    description:
      'Have questions for us? Use our support portal or contact us today!',
  },
  {
    id: 4,
    icon: 'flaticon-briefing',
    title: 'E Commerce App/Plug-in',
    description:
      'You can install your App or plug-in from you E-commerece store.',
  },
  {
    id: 5,
    icon: 'flaticon-trophy',
    title: 'Online Documentation',
    description:
      'We provide detailed documentation to help you connect your service to our API. Get started today!',
  },
  {
    id: 6,
    icon: 'flaticon-atom',
    title: 'Account Portal Access',
    description:
      'Access our portal to view account details, lookup rates, and manage your subscription .',
  },
];

export const Timeline = [
  {
    title: 'Plan Features',
    description:
      'All subscription plans include full api access, account portal access with online lookup feature, monthly data updates, and 24/7 support.',
  },
  {
    title: 'How to subscribe?',
    description:
      'Simply choose your plan and click "Subscribe". You will be redirected to our checkout page to complete your subscription. .',
  },
  {
    title: 'Get started today',
    description:
      'Subscribe now to receive your developer API key and begin integrating your applications today!.',
  },
];

export const Testimonial = [
  {
    id: 1,
    name: 'Michal Andry',
    designation: 'Ceo of Invission Co.',
    comment:
      'Love to work with this designer in every our future project to ensure we are going to build best prototyping features.',
    avatar_url: Client1,
    social_icon: 'flaticon-instagram',
  },
  {
    id: 2,
    name: 'Roman Ul Oman',
    designation: 'Co-founder of QatarDiaries',
    comment:
      'Impressed with master class support of the team and really look forward for the future. A true passionate team.',
    avatar_url: Client2,
    social_icon: 'flaticon-twitter-logo-silhouette',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '70',
  },
  {
    label: 'Service',
    path: '#service_section',
    offset: '70',
  },
  {
    label: 'Feature',
    path: '#feature_section',
    offset: '70',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '70',
  },
  {
    label: 'Documentation',
    path: 'https://taxigydocs.web.app/',
    offset: '70',
  },
  {
    label: 'Contact Us',
    path: '#faq_section',
    offset: '70',
  },
  {
    label: 'Log In',
    path: 'https://taxigydashboard.web.app/',
    offset: '70',
  },
  {
    label: 'Register',
    path: 'https://taxigydashboard.web.app/register',
    offset: '70',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    // description: 'For Small teams or group who need to build website ',
    price: '$9.00',
    priceLabel: '1000 request per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
  {
    name: 'Standard Account',
    // description: 'For Mediums teams or group who need to build website ',
    price: '$19.00',
    priceLabel: '10,000 requests Per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
  {
    name: 'Premium Account',
    // description: 'For Large teams or group who need to build website ',
    price: '$29.00',
    priceLabel: '100,000 requests per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Geo Basic ',
    description: 'Address level details ',
    price: '$0',
    priceLabel: '1000 requests per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
  {
    name: 'Geo Standard',
    description: 'Address level details ',
    price: '$99.00',
    priceLabel: '10,000 requests Per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
  {
    name: 'Geo Premium',
    description: 'Address level details ',
    price: '$199.00',
    priceLabel: '100,000 Per month',
    buttonLabel: 'Subscribe Now',
    url: 'https://taxigydashboard.web.app/register',
    listItems: [
      {
        content: 'Unlimited Calls',
      },
      {
        content: '24/7 Call Support',
      },
      {
        content: 'Intigration with all Languages',
      },
      {
        content: 'Woocommerce Plugin',
      },
      {
        content: 'Search By street address',
      },
    ],
  },
];
