import React, { Fragment, useState, useEffect } from "react";

import PropTypes from "prop-types";
import Link from "next/link";
import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import Box from "@reusecore/src/elements/Box";
import Text from "@reusecore/src/elements/Text";
import Input from "@reusecore/src/elements/Input";
import Heading from "@reusecore/src/elements/Heading";
import Button from "@reusecore/src/elements/Button";
import Card from "@reusecore/src/elements/Card";
import Image from "@reusecore/src/elements/Image";
import FeatureBlock from "../../../components/FeatureBlock";
import Container from "../../../components/UI/Container";
import VisitorSectionWrapper, { SectionObject } from "./visitor.style";
import visitorimage from "../../../assets/image/saas/visitorimage.png";
import Modal from "react-modal";
import taxData from "./Json";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import GetRequestButton from "./../../../data/requestData/getPostRequest";

Modal.setAppElement("#___gatsby");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "30rem",
    // overflow: "scroll",
    border: "none",
    background: "white",
  },
};

const VisitorSection = ({
  col,
  title,
  description,
  textArea,
  imageWrapper,
  btnStyle,
  inputStyle,
  button,
}) => {
  var subtitle;
  const args = React.useRef(["a"]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [mapValue, setMapValue] = React.useState(false);
  const [getTokenValue, setTokenValue] = React.useState(false);
  const [getZipCode, setZipCode] = React.useState();
  const [getTaxData, setTaxData] = React.useState([{}]);
  const googleMapAPi = "AIzaSyCfIp7Iwxr4tdu0RDHT6jPAjJOliBxlgQE";
  const [county, setCounty] = React.useState("");
  useEffect(() => {
    if(mapValue){
    var address = mapValue.label.split(' ').join('+');
    setCounty(address);
    }
  }, [mapValue]);
  
  function openModal(data) {
    if(data){
    let t = data.results[0];
    delete t.id;
    delete t.NormalizedCounty;
    delete t.NormalizedCity;
    delete t.Normalized;
    delete t.Month;
    t.CombinedRate = t.CombinedRate + "%";
    t.StateRate = t.StateRate + "%";
    t.CityRate = t.CityRate + "%";
    t.CountyRate = t.CountyRate + "%";
    t.SpecialRate = t.SpecialRate + "%";
    setTaxData(data.results[0]);
    setIsOpen(true);
    }
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  React.useEffect(() => {
    // Update the document title using the browser API
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: "anas.ahmed142@gmail.com",
        password: "anas142",
      }),
    };
    fetch(
      "https://us-central1-taxigy-bfe19.cloudfunctions.net/api/v10/user/login",
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();
        //
        setTokenValue(data.results[0].token);

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        // this.setState({ postId: data.id })
      })
      .catch((error) => {
        // this.setState({ errorMessage: error.toString() });
        console.error("There was an error!", error);
      });
  }, [args]);

  return (
    <VisitorSectionWrapper id="visitorSection">
      <SectionObject>
        <Card className="objectWrapper" {...imageWrapper}>
          <Zoom>
            <Image src={visitorimage} alt="BgImage" />
          </Zoom>
          <Card className="dashboardWrapper" {...imageWrapper}>
            <Fade left>
              {/* <Image src={ImageOne} alt="VisitorDashboard1" /> */}
            </Fade>
          </Card>
        </Card>
      </SectionObject>
      <Container>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}></h2>
          {/* <button {...btnStyle} onClick={closeModal}>close</button>   */}
          {/* <div style={{justifyContent:'center'}}> */}
          <table
            style={{
              fontSize: "16px",
              color: "#343d48cc",
              textAlign: "justify",
              fontFamily: "inherit",
              margin: "0 auto",
              width: "450px",
              background: "white",
              fontFamily: "Roboto',sans-serif",
              borderCollapse: "collapse",
            }}
          >
            <thead style={{ textAlign: "center" }}>
              <tr style={{ backgroundColor: "#6a69f6" }}>
                <th
                  style={{
                    textAlign: "justify",
                    color: "white",
                    padding: "5px",
                  }}
                >
                  Head
                </th>
                <th
                  style={{
                    textAlign: "justify",
                    color: "white",
                    padding: "5px",
                  }}
                >
                  Values
                </th>
              </tr>
            </thead>

            <tbody>
              {Object.keys(getTaxData).map(function(key, index) {
                return (
                  <tr key={index} style={{}}>
                    <td style={{ padding: "5px", border: "0.2px solid gray" }}>
                      {key}
                    </td>
                    <td style={{ padding: "5px", border: "0.2px solid gray" }}>
                      {getTaxData[key]}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* </div> */}
        </Modal>

        <Box {...textArea}>
          
          <FeatureBlock
            title={<Heading content="Sales Tax LookUp" {...title} />}
          />

          <FeatureBlock
            description={
              <Text
                content={
                  "Enter a valid zip code in the box below then click Get Sales Tax Rates. Your results will be displayed on this page. Thank you for trying taxigy.com"
                }
                {...description}
              />
            }
          />

          <div>
            <div style={{ width: "70%", display: "inline-block" }}>
              {" "}
              <Input
                type="text"
                onChange={(event) => setZipCode(event)}
                required={true}
                placeholder="Zip Code"
              />
            </div>
            <div style={{ display: "inline-block", width: "30%" }}>
                <GetRequestButton
                  openModalFunc={openModal}
                  from={"zipCode"}
                  data={getZipCode}
                  buttonStyle={btnStyle}
                  token={getTokenValue}
                />
              
            </div>
          </div>
          <FeatureBlock
            description={
              <Text
                content={""}
                {...description}
                style={{ marginTop: "20px", width: "20rem" }}
              />
            }
          />
          <div>
            <div style={{ color: "#343d48cc", fontWeight: "bold" }}>
              Search By Location
            </div>
            <div style={{ width: "70%", display: "inline-block" }}>
              {" "}
              <GooglePlacesAutocomplete
                apiKey="AIzaSyCfIp7Iwxr4tdu0RDHT6jPAjJOliBxlgQE"
                selectProps={{ mapValue, onChange: setMapValue }}
                autocompletionRequest={{componentRestrictions:{country: 'us'}}}
              />
            </div>
            <div style={{ display: "inline-block", width: "30%" }}>
                <GetRequestButton
                  openModalFunc={openModal}
                  from={"location"}
                  data={county}
                  buttonStyle={btnStyle}
                  token={getTokenValue}
                />
              
            </div>
          </div>
        </Box>
      </Container>
    </VisitorSectionWrapper>
  );
};

VisitorSection.propTypes = {
  title: PropTypes.object,
  description: PropTypes.object,
  btnStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  button: PropTypes.object,
};

VisitorSection.defaultProps = {
  textArea: {
    width: ["100%", "100%", "45%"],
    ml: [0, 0, "58%"],
  },
  imageWrapper: {
    boxShadow: "none",
  },
  title: {
    fontSize: ["20px", "26px", "26px", "36px", "48px"],
    fontWeight: "400",
    color: "#0f2137",
    letterSpacing: "-0.010em",
    mb: "20px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
    lineHeight: "1.5",
  },
  description: {
    fontSize: "16px",
    color: "#343d48cc",
    lineHeight: "1.75",
    mb: "33px",
    maxWidth: ["100%", "100%", "100%", "440px", "440px"],
  },
  btnStyle: {
    minWidth: "156px",
    fontSize: "14px",
    fontWeight: "500",
    color: "#fff",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    ml: "40%",
    // ml:'5%'
    // mb:'70%',
    // ml: [0,0,'58%'],
  },
  row: {
    flexBox: true,
    flexWrap: "wrap",
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
  },
  // btnStyle2:{
  //   marginLeft:'15.5rem'
  // },
  inputStyle: {
    border: "0px",
    borderBottom: "2px solid gray",
    color: "gray",
    fontSize: "1.3rem",
    padding: "7px 0",
    //     focus:{
    //       fontSize:'1rem',
    //       color:'black',
    //       fontWeight:'700px',
    //       transition:'0.2s',
    // }
  },
  button: {
    marginTop: "100px",
    type: "button",
    fontSize: "14px",
    fontWeight: "600",
    color: "#fff",
    borderRadius: "4px",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
  },
};

export default VisitorSection;
